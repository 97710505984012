import React from 'react'
import { Link } from 'gatsby'

const mapUsps = (item) => {
  if (item?.link?.url) {
    return (
      <Link key={item.text} to={item.link.url}>
        <div className={'grid grid-rows-2 gap-2 justify-items-center'}>
          <img
            className={'w-8 h-8'}
            src={item.icon.sourceUrl}
            alt={item.icon.altText ? item.icon.altText : ''}
          />

          <p
            className={
              'text-[15px] decoration-2 underline underline-offset-8 decoration-orange-main leading-[15px] md:text-[17px] md:leading-[17px] text-blue-dark font-medium'
            }
          >
            {item.text}
          </p>
        </div>
      </Link>
    )
  } else {
    return (
      <div
        key={item.text}
        className={'grid grid-rows-2 gap-2 justify-items-center'}
      >
        <img
          className={'w-8 h-8'}
          src={item.icon.sourceUrl}
          alt={item.icon.altText ? item.icon.altText : ''}
        />

        <p
          className={
            'text-[15px] leading-[15px] md:text-[17px] md:leading-[17px] text-blue-dark font-medium'
          }
        >
          {item.text}
        </p>
      </div>
    )
  }
}

const UniqueSellingPoints = ({ data }) => {
  return (
    <div className={'w-screen mx-auto bg-white relative'} id={'usps'}>
      <div
        className={
          'xl-container flex mx-auto  gap-y-4  gap-x-7 md:gap-x-32 lg:gap-x-7  flex-wrap justify-around  sm:flex-wrap-reverse pt-0 pb-10  md:px-8 md:pb-10 lg:pt-10 lg:pb-0'
        }
      >
        {data.map((item) => mapUsps(item))}
      </div>
    </div>
  )
}

export default UniqueSellingPoints
